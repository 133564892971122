const $ = require('jquery');

import picturefill from 'picturefill';
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/aspectratio/ls.aspectratio';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import slick from 'slick-carousel';

const script = (() => {

	// 変数宣言
	const pointSP = window.matchMedia('screen and (max-width: 767px)');
	let scrollY;

	// このJSのinit
	function init() {

		AOS.init({
			offset: 100,
			duration: 1200,
			once: true
		});

		smoothScroll();
		menuTrigger();
		tab();

		waveAnimation();
	}

	// 関数 ------------------------------------
	function fixedBG() {
		scrollY = $(window).scrollTop();
		$('body').addClass('is-fixed').css('top', -scrollY);
	}

	function fixedBGReset() {
		$('body').removeClass('is-fixed').removeAttr('style');
		$('html, body').scrollTop(scrollY);
	}

	function smoothScroll() {
		$('a[href^="#"]').on('click.smoothScroll', (event) => {
			const speed = 1200;// ミリ秒
			const href = $(event.currentTarget).attr("href");
			const target = $(href == "#" || href == "" ? 'html' : href);
			const position = target.offset().top;
			$('html, body').animate({ scrollTop: position }, speed, 'swing');
			return false;
		});
	}

	function menuTrigger() {
		const $trigger = $('[aria-controls="nav-global"]');
		const $nav = $('.' + $trigger.attr('aria-controls'));
		const $anchor = $nav.find('a[href^="#"]');

		$trigger.on('click', () => {
			const expanded = $trigger.attr('aria-expanded');

			if (expanded === 'false') {
				$trigger.attr('aria-expanded', true);
				$nav.attr('aria-hidden', false).addClass('is-active');
				fixedBG();
			} else {
				$trigger.attr('aria-expanded', false);
				$nav.attr('aria-hidden', true).removeClass('is-active');
				fixedBGReset();
			}
		});

		function checkBreakPoint(pointSP) {
			if (pointSP.matches) {
				$trigger.attr('aria-expanded', false);
				$nav.attr('aria-hidden', true).removeClass('is-active');
			} else {
				$trigger.attr('aria-expanded', true);
				$nav.attr('aria-hidden', false).addClass('is-active');
				fixedBGReset();
			}
		}

		pointSP.addListener(checkBreakPoint);
		checkBreakPoint(pointSP);

		$anchor.off('click.smoothScroll').on('click', (event) => {
			fixedBGReset();

			$trigger.attr('aria-expanded', false);
			$nav.attr('aria-hidden', true).removeClass('is-active');

			const speed = 1200;// ミリ秒
			const href = $(event.currentTarget).attr("href");
			const target = $(href == "#" || href == "" ? 'html' : href);
			const position = target.offset().top;
			$('html, body').animate({ scrollTop: position }, speed, 'swing');
			return false;
		});
	}

	function tab() {
		const $trigger = $('[aria-controls*="year"]');
		const $select = $('.js-tab-select');

		$trigger.on('click', (event) => {
			const $self = $(event.currentTarget);
			const expanded = $self.attr('aria-expanded');
			const $target = $('#' + $self.attr('aria-controls'));
			const $other = $target.find('[aria-controls*="year"]');

			if (expanded === 'false') {
				$trigger.attr('aria-expanded', false);
				$trigger.filter('[aria-controls="'+ $self.attr('aria-controls') +'"]').attr('aria-expanded', true);
				$target.attr('aria-hidden', false).siblings('[id]').attr('aria-hidden', true);
			}

			$select.find('[value="'+ $self.attr('aria-controls') +'"]').prop('selected',true);
		});

		$select.on('change', (event) => {
			const $self = $(event.currentTarget);
			const $target = $self.val();

			$('#' + $target).attr('aria-hidden', false).siblings('[id]').attr('aria-hidden', true);
			$trigger.attr('aria-expanded', false);
			$trigger.filter('[aria-controls="'+ $target +'"]').attr('aria-expanded', true);
		});
	}

	function waveAnimation() {
		let unit = 100,
		    canvasList,
		    drawInfo = {},
		    colorList = [],
		    innerFontColorList = [],
		    innerBgColorList = [];

		function wave(factor) {
			drawInfo.seconds = 0;
			drawInfo.t = 0;

			// Get all canvas elements
			canvasList = document.querySelectorAll('.wave-bar');

			// Initialize each canvas
			Array.prototype.forEach.call(canvasList,
				function(canvas, index){
					let canvasDataSet = canvas.dataset,
					    inner = canvas.nextElementSibling,
					    innerDataSet;
					// Set the canvas size (Retina ready)
					canvas.width = document.documentElement.clientWidth;
					canvas.width *= devicePixelRatio;
					canvas.height = canvas.width * factor;
					canvas.height *= devicePixelRatio;
					canvas.style.height = String(canvas.height / devicePixelRatio) + "px";

					canvas.contextCache = canvas.getContext("2d");

					// Add color
					colorList.push([canvasDataSet.color1, canvasDataSet.color2, canvasDataSet.color3]);

					// Wrapper background color
					if (canvasDataSet.bgcolor) {
						canvas.parentNode.style.backgroundColor = canvasDataSet.bgcolor;
					}

					// Inner content area
					if (inner){
						innerDataSet = inner.dataset;
						if (innerDataSet.color){
							inner.style.color = innerDataSet.color;
						}
						if (innerDataSet.bgcolor){
							inner.style.backgroundColor = innerDataSet.bgcolor;
						}
					}
				}
			);
			update();
		}

		function update() {
			// Each canvas
			Array.prototype.forEach.call(canvasList,
				function(canvas, index){
					draw(canvas, colorList[index]);
				}
			);
			// 共通の描画情報の更新
			drawInfo.seconds = drawInfo.seconds + .005;
			drawInfo.t = drawInfo.seconds*Math.PI;
			// Re-call
			setTimeout(update, 35);
		}

		/**
		 * Draw animation function.
		 *
		 * This function draws one frame of the animation, waits 20ms, and then calls
		 * itself again.
		 */
		function draw(canvas, color) {
			// 対象のcanvasのコンテキストを取得
			const context = canvas.contextCache;
			// キャンバスの描画をクリア
			context.clearRect(0, 0, canvas.width, canvas.height);

			//波を描画
			if (color[0]) {
				if (color[1] && color[2]) {
					// 3 waves
					drawWave(canvas, color[0], 1, 2.4, 0);
					drawWave(canvas, color[1], 1, 1.6, 160);
					drawWave(canvas, color[2], 1, 1.2, 30);
				} else if (color[1] && !color[2]) {
					// 2 waves
					drawWave(canvas, color[0], 1, 2.4, 0);
					drawWave(canvas, color[1], 1, 1.6, 160);
				} else {
					// single wave only
					drawWave(canvas, color[0], 1, 1.6, 0);
				}
			}
		}
		/**
		* 波を描画
		* drawWave(キャンバス, 色, 不透明度, 波の幅のzoom, 波の開始位置の遅れ)
		*/
		function drawWave(canvas, color, alpha, zoom, delay) {
			const context = canvas.contextCache;
			context.fillStyle = color;
			context.globalAlpha = alpha;

			context.beginPath(); //パスの開始
			drawSine(canvas, drawInfo.t / 1, zoom, delay);
			context.lineTo(canvas.width + 10, canvas.height); //パスをCanvasの右下へ
			context.lineTo(0, canvas.height); //パスをCanvasの左下へ
			context.closePath() //パスを閉じる
			context.fill(); //塗りつぶす
		}
		/**
		 * Function to draw sine
		 *
		 * The sine curve is drawn in 10px segments starting at the origin.
		 * drawSine(キャンバス, 時間, 波の幅のzoom, 波の開始位置の遅れ)
		 */
		function drawSine(canvas, t, zoom, delay) {
			let xAxis = Math.floor(canvas.height/2);
			let yAxis = 0;
			let context = canvas.contextCache;
			// Set the initial x and y, starting at 0,0 and translating to the origin on
			// the canvas.
			let x = t; //時間を横の位置とする
			let y = Math.sin(x)/zoom;
			context.moveTo(yAxis, unit*y+xAxis); //スタート位置にパスを置く

			// Loop to draw segments (横幅の分、波を描画)
			for (let i = yAxis; i <= canvas.width + 10; i += 10) {
				x = t+(-yAxis+i)/unit/zoom;
				y = Math.sin(x - delay)/3;
				context.lineTo(i, unit*y+xAxis);
			}
		}

		function checkBreakPoint(pointSP) {
			if (pointSP.matches) {
				wave(.2);
			} else {
				wave(.1);
			}
		}

		pointSP.addListener(checkBreakPoint);
		checkBreakPoint(pointSP);
	}
	// -----------------------------------------

	return {
		init : init
	};

})();

// このタイミングで実行
$(() => {
	script.init();
});
